.App {
  text-align: center;
}

.podcast-audio .styles_reactWaves__1M36F {
  width: 95% !important;
}

.play-pause i {
  width: 60px;
  height: 60px;
  border-radius: 100%;
  background-color: aquamarine;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  color: #000;
}

.wave {
  height: 58px !important;
}

@import url("https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap");

.ff {
  font-family: "Roboto" !important;
  font-optical-sizing: auto;
  /* font-weight: weight; */
  font-style: normal;
}

.profile-menus li:hover,
.dropdown-body .list-apps li:hover,
.dropdown-inner:hover .sign-out {
  background-color: #a5a6a91c;
}

.dropdown-body .list-apps li {
  border-radius: 5px;
  transition: all 0.1s ease-in-out;
}

.sidebar {
  position: fixed;
  top: 45px;
  left: 0;
  width: 150px;
  height: 100%;
  transition: width 0.3s ease-in-out;
  /* background-color: #000; */
}

.sidebar.collapsed {
  width: 60px;
  transition: width 0.3s ease-in-out;
}

/* .sibar_list {
  padding: 0;
  margin: 0;
  height: auto;
  width: 150px;
  background-color: aqua;
}

.link_list {
  transition: width 0.3s ease-in-out;
} */
/* 
.toggle-btn {
  position: absolute;
  bottom: 50px;
  right: 12px;
  background: rgb(202, 194, 194);
  border: none;
  border-radius: 50%;
  padding: 10px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
} */

/* card overlay */
.card-overlay {
  position: absolute;
  bottom: 105px;
  left: 3%;
  width: 94%;
  padding: 20px;
  box-sizing: border-box;
}

/* Large screens (max-width: 1400px) */
@media (max-width: 1400px) {
  .card-overlay {
    bottom: 160px;
    left: 3%;
    width: 90%;
  }
}

/* Medium screens (max-width: 1200px) */
@media (max-width: 1200px) {
  .card-overlay {
    position: absolute;
    bottom: 105px;
    left: 3%;
    width: 94%;
  }
}

/* Tablets (max-width: 992px) */
@media (max-width: 992px) {
  .card-overlay {
    position: absolute;
    bottom: 105px;
    left: 3%;
    width: 94%;
  }
}

/* Small screens (max-width: 768px) */
@media (max-width: 768px) {
  .card-overlay {
    position: absolute;
    bottom: 115px;
    left: 3%;
    width: 94%;
  }
}

/* Extra small screens (max-width: 576px) */
@media (max-width: 576px) {
  .card-overlay {
    position: absolute;
    bottom: 115px;
    left: 3%;
    width: 94%;
    height: 100%;
    padding: 20px;
  }
}

.slider_card {
  height: 650px !important;
}

.slick-slider .slick-list {
  height: 580px;
}

.slick-track {
  height: 530px;
}

/* Marquee.css */
.marquee {
  position: relative;
  height: 90px;
  overflow-x: hidden;
  font-size: 16px;
  font-weight: 500;
  letter-spacing: 2px;
  color: black;
}

.track {
  display: flex;
  align-items: center;
  white-space: nowrap;
  will-change: transform;
  animation: marquee 50s linear infinite;
}

@keyframes marquee {
  0% {
    transform: translateX(0);
  }

  100% {
    transform: translateX(-50%);
  }
}

.marquee_content {
  white-space: nowrap;
}

.marquee_content span {
  margin-right: 15px;
}

/* NewsToggle.css */
.news-toggle h5 {
  display: flex;
  gap: 10px;
}

.news-toggle a {
  text-decoration: none;
  color: #333;
  padding: 5px 10px;
  border-radius: 15px;
  transition: all 0.3s ease;
}

.news-toggle a.active {
  color: #ff0000;
}

.news-toggle a:hover:not(.active) {
  background-color: #e9ecef;
}

.news-toggle span {
  color: #6c757d;
}

.icon-hover {
  color: #6c757d;
  display: inline-block;
  transition: transform 0.3s ease-in-out;
}

.icon-hover.facebook:hover {
  color: #316ff6;
  transform: scale(1.3);
}

.icon-hover.instagram:hover {
  background: linear-gradient(45deg, #feda75, #fa7e1e, #d62976, #962fbf, #e1306c);
  background-clip: text;
  -webkit-background-clip: text;
  color: transparent;
  transform: scale(1.3);
  transition: all 0.3s ease;
}

.icon-hover.twitter:hover {
  color: #1da1f2;
  transform: scale(1.3);
}

.icon-hover.youtube:hover {
  color: #ff0000;
  transform: scale(1.3);
}

.form-group {
  flex: 1;
}

.navbar-toggler {
  padding: 0.25rem 0.75rem;
  font-size: 1rem;
  line-height: 1.5;
  border: none;
  border-radius: 0.25rem;
}

.navbar-collapse {
  flex-grow: 1;
}

.fixed-image {
  position: fixed;
  bottom: 0;
  left: 0;
  width: 50px;
  height: 50px;
  margin: 10px;
  padding: 1px;
}

.header-calendar {
  left: 50%;
  position: absolute;
  transform: translateX(-50%);
}

.text-blue-color {
  color: #008dd2;
}

.head-icon {
  color: #5671a7;
}

.list-apps-media img {
  height: -webkit-fill-available;
  width: -webkit-fill-available;
  object-fit: contain;
}

/* General hover and zoom effect */
.hover-zoom {
  overflow: hidden;
}

.hover-zoom figure {
  height: 100%;
  margin: 0;
}

.hover-zoom figure img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  transform: scale(1);
  transition: transform 0.3s ease-in-out;
}

.hover-zoom:hover figure img {
  transform: scale(1.2);
}

.image-container {
  height: 200px;
  overflow: hidden;
  position: relative;
}

.image-zoom-container {
  display: block;
  overflow: hidden;
  position: relative;
}

.image-zoom-container .zoom-image {
  width: 100%;
  height: 100%;
  object-fit: cover;
  transition: transform 0.3s ease-in-out;
}

.image-zoom-container:hover .zoom-image {
  transform: scale(1.1);
}

@media (max-width: 768px) {
  .image-container {
    height: 150px;
  }

  .hover-zoom figure img {
    transition: transform 0.2s ease-in-out;
  }

  .image-zoom-container .zoom-image {
    transform: scale(1);
  }

  .image-zoom-container:hover .zoom-image {
    transform: scale(1.05);
  }
}

/* Hide scrollbar for Chrome, Safari and Opera */
.hide-scrollbar::-webkit-scrollbar {
  display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
.hide-scrollbar {
  -ms-overflow-style: none;
  scrollbar-width: none;
}

/* Custom Scrollbar */
.custom-scrollbar::-webkit-scrollbar {
  width: 4px !important;
  height: 4px !important;
  border-radius: 10px !important;
}

.custom-scrollbar::-webkit-scrollbar-track {
  background: rgb(255, 255, 255) !important;
}

.custom-scrollbar::-webkit-scrollbar-thumb {
  background: rgb(138, 150, 138) !important;
  border-radius: 10px !important;
}

/* Apply underline on hover */
.news-link {
  text-decoration: none;
}

.news-link:hover .card-inner {
  text-decoration: underline;
}

/* Sound bar */
#bars {
  display: inline-flex;
  justify-content: center;
  align-items: center;
}

.bar {
  background: #52467b;
  bottom: 1px;
  height: 3px;
  width: 10px;
  margin: 0px 4px;
  border-radius: 5px;
  animation: sound 0ms -600ms linear infinite alternate;
}

@keyframes sound {
  0% {
    opacity: 0.35;
    height: 3px;
  }

  100% {
    opacity: 1;
    height: 60px;
  }
}

.bar:nth-child(1) {
  animation-duration: 474ms;
  background: #ff0000;
}

.bar:nth-child(2) {
  animation-duration: 433ms;
  background: #ff7f00;
}

.bar:nth-child(3) {
  animation-duration: 407ms;
  background: #ffff00;
  background-color: #0000ff;
}

.bar:nth-child(4) {
  animation-duration: 458ms;
  background: #00ff00;
}

.bar:nth-child(5) {
  animation-duration: 400ms;
  background: #0000ff;
}

#bars.active .bar {
  height: 60px;
  animation-duration: 0ms;
}

#bars.active .bar:nth-child(1) {
  background: #ff0000;
  height: 35px;
}

#bars.active .bar:nth-child(2) {
  background: #ff7f00;
  height: 60px;
}

#bars.active .bar:nth-child(3) {
  background: #00afef;
  height: 35px;
}

#bars.active .bar:nth-child(4) {
  background: #00a859;
  height: 60px;
}

#bars.active .bar:nth-child(5) {
  background: #393185;
  height: 35px;
}

.h-100px {
  height: 100px;
}

.h-50px {
  height: 30px;
}

.position-absolute-textfile {
  position: absolute;
  top: -7px;
  right: 0;
}

.h-180px {
  height: 180px;
}

.button_class {
  color: #ff0000;
}

.sign-in-btn {
  background: #f40404 !important;
  color: #fff !important;
}

.voice-model .modal-content {
  width: 95% !important;
}

.voice-model {
  width: auto !important;
  margin-right: 0 !important;
}

@media (max-width: 426px) {
  .voice-mode .modal-content {
    width: 100% !important;
  }
}

@media (max-width: 568px) {
  .nk-header-logo .header-logo-img {
    content: url("./images/personaimg/main_logo.png");
    height: 20px;
  }
}
