.ql-tooltip {
  display: none !important;
}
.ql-clipboard {
  display: none !important;
}
.featurette-heading,
.description {
  word-break: break-all;
}
.DraftEditor-editorContainer {
  text-align: left;
}
.editor-wrapper {
  position: relative;
}

.tooltip {
  position: absolute;
  top: 100%;
  left: 50%;
  transform: translateX(-50%);
  padding: 5px;
  background-color: rgba(0, 0, 0, 0.8);
  color: #fff;
  border-radius: 5px;
  z-index: 1;
  display: none;
}

.truncated:hover + .tooltip {
  display: block;
}
.blog-detail-page {
  border: 1px solid gray;
  border-radius: 10px;
}
.blogs {
  margin-left: 30px;
}
.blog,
.blogdetails {
  overflow-x: hidden;
  overflow-y: hidden;
}
@media (max-width: 768px) {
  .blogdetails {
    overflow-x: auto;
    overflow-y: auto;
  }
  .blogs {
    margin-left: 0;
  }
}
.list-group-item {
  background-color: #e8eff7;
}

.list-group-item:hover {
  background-color: #a5aeb530 !important;
}
