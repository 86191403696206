/* Blog Head */

.blogheadimg {
  width: 280px !important;
  height: 150px !important;
}

/* Blog Body */
.silo-divider {
  margin: 40px 0px !important;
}

/* Blog Details */
.Blogdetailsfoot {
  background-image: linear-gradient(rgba(0, 0, 0, 0.553), rgba(0, 0, 0, 0.515)), url(../images/blog.png) !important;
  width: 1296px !important;
  height: 38px !important;
  display: flex;
  align-items: center;
  justify-content: center;
}

/* Blogcard */
.card .img-fluid {
  margin: 20px 67px !important;
  height: 400px !important;
  width: 500px !important;
}
.card-body h5 {
  text-align: justify;
}
.card-body p {
  text-align: justify;
  font-size: 15px !important;
}

/* Blogitem */
.blogitem h4 {
  text-align: justify !important;
}
.lead {
  margin: 8px 0px !important;
  font-size: 18px !important;
  text-align: justify !important;
}

/* sidebar */
#sidebar {
  z-index: 999 !important;
}

@media only screen and (max-width: 1399px) {
  /* Blogcard */
  .card .img-fluid {
    margin: 20px 70px !important;
    height: 320px !important;
    width: 400px !important;
  }
  .card-body h5 {
    font-size: 20px !important;
    text-align: justify;
  }
  .card-body p {
    text-align: justify;
    font-size: 15px !important;
  }
}

@media only screen and (max-width: 1200px) {
  /* Blogcard */
  .card .img-fluid {
    margin: 18px 50px !important;
    height: 280px !important;
    width: 350px !important;
  }
  .card-body h5 {
    font-size: 16px !important;
    text-align: justify;
  }
  .card-body p {
    text-align: justify;
    font-size: 14px !important;
  }

  /* Blogitem */
  /* .featurette div img {
    padding-top: 50px !important;
  } */
  .blogitem {
    padding-top: 50px;
    padding-bottom: 10px;
  }
  .blogitem h4 {
    font-size: 18px !important;
    margin-bottom: 20px !important;
  }
  .lead {
    margin: 10px 0px !important;
    font-size: 16px !important;
  }
  .blogitem p {
    margin-bottom: 0px !important;
  }
  .blogitemrow div i {
    font-size: 16px !important;
  }
  .blogitemrow div p {
    padding-top: 2px !important;
    font-size: 14px !important;
  }
}

@media only screen and (max-width: 992px) {
  /* Blogcard */
  .card .img-fluid {
    margin: 18px 16px !important;
    height: 240px !important;
    width: 300px !important;
  }

  /* Blog */
  .silo-divider {
    margin: 20px 0px !important;
  }
  /* .featurette div img {
    padding: 50px 0px !important;
  } */
  .blogitem h4 {
    font-size: 16px !important;
    margin-bottom: 20px !important;
  }
  .lead {
    margin: 10px 0px !important;
    font-size: 12px !important;
  }
  .blogitemrow div i {
    font-size: 12px !important;
  }
  .blogitemrow div p {
    padding-top: 6px !important;
    font-size: 10px !important;
  }
}

@media only screen and (max-width: 768px) {
  /* Blogcard */
  .card .img-fluid {
    height: 400px !important;
    width: 480px !important;
  }

  /* Blog */
  .silo-divider {
    margin: 10px 0px !important;
  }

  /* Blog-item */
  .blogitem h4 {
    font-size: 20px !important;
    margin-bottom: 20px !important;
  }
  .lead {
    margin: 10px 0px !important;
    font-size: 14px !important;
  }
  .blogitemrow div i {
    font-size: 16px !important;
  }
  .blogitemrow div p {
    padding-top: 1px !important;
    font-size: 14px !important;
  }
}

@media only screen and (max-width: 576px) {
  /* Blog-head */
  .blogheadimg {
    width: 180px !important;
    height: 96px !important;
  }
  .blog {
    padding: 30px !important;
  }

  /* Blogcard */
  .card .img-fluid {
    padding-top: 20px !important;
    margin: auto !important;
    height: 272px !important;
    width: 340px !important;
  }
}

@media only screen and (max-width: 386px) {
  /* Blog-head */
  .blogheadimg {
    width: 120px !important;
    height: 54px !important;
  }

  /* Blogcard */
  .card .img-fluid {
    padding-top: 20px !important;
    margin: auto !important;
    height: 224px !important;
    width: 280px !important;
  }
}

@media only screen and (max-width: 346px) {
  /* Blogcard */
  .card .img-fluid {
    height: 176px !important;
    width: 220px !important;
  }

  /* Blog-item */
  .blogitemrow div i {
    font-size: 14px !important;
  }
  .blogitemrow div p {
    padding-top: 4px !important;
    font-size: 12px !important;
  }
}
/* .active>.page-link, .page-link.active {
  z-index: 3;
  color: #fff!important;
  background-color: rgb(140, 8, 33)!important;;
  border-color: rgb(140, 8, 33)!important;;
}
.page-link {
  color: #000!important;
} */
