@import url("https://fonts.googleapis.com/css2?family=Manrope:wght@500&display=swap");

* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
  font-family: "Manrope", sans-serif;
}

/*section-header*/
:root {
  --khoisan-theme-color: #8c0821;
  --khoisan-text-color: #ffc527;
  --khoisan-theme-text-color: #000;
  --navbar-background-color: #000;
  --navbar-text-color: #fff;
}

body {
  color: #61666f;
}

a {
  text-decoration: none;
}

.text-theme {
  color: var(--khoisan-theme-color) !important;
}

.fw-300 {
  font-weight: 300;
}

.text-color {
  color: var(--theme-text-color);
}

.text-theme {
  color: var(--text-theme);
}

.theme-btn {
  background-color: var(--primary-theme);
  padding: 12px 32px;
  font-size: 18px;
  outline: none;
  border: none;
  color: var(--btn-text);
  font-weight: 600;
  transition: var(--trans2);
  text-decoration: none;
}

.theme-btn:hover {
  color: var(--btn-text);
  background-color: #ffc527;
}

.theme-btn-contact {
  background-color: var(--khoisan-theme-color);
  padding: 12px 100px;
  font-size: 18px;
  outline: none;
  border: none;
  color: var(--btn-text);
  font-weight: 600;
  transition: var(--trans2);
}

.theme-btn-contact:hover {
  color: var(--btn-text);
  background-color: #ffc527;
}

.hero-section1 {
  background-color: white;
  box-shadow: rgba(0, 0, 0, 0.1) 0px 0px 15px 0px, rgba(0, 0, 0, 0.05) 0px 4px 5px 0px;
}

.logo {
  height: 60px;
}

.section-header .navbar-collapse .nav-link {
  color: #526484;
  font-size: 14px;
  font-weight: 400;
  padding: 0.5rem 1rem;
  border-top: 2px solid transparent;
  border-bottom: 2px solid transparent;
  margin: 0px 1rem;
  transition: var(--trans2);
}

.nav-pills {
  white-space: nowrap;
}

#navPill::-webkit-scrollbar {
  height: 4px !important;
  margin-top: 5px !important;
  background-color: transparent;
}

#navPill::-webkit-scrollbar-thumb:active {
  background: rgba(0, 0, 0, 0.7);
}

#navPill::-webkit-scrollbar-thumb {
  background: var(--khoisan-theme-color) !important;
  border-radius: 6px;
}

.navbar-toggler {
  box-shadow: none !important;
  outline: none !important;
  border: none !important;
}

.nav-link {
  border-radius: 0 !important;
}

.buttontabs {
  border: 1px solid var(--khoisan-theme-color) !important;
  border-radius: 0 !important;
}

.buttontabs:hover {
  background-color: var(--primary-theme) !important;
  color: #ffffff !important;
}

.nav-tabs .nav-link:after {
  left: 0;
  right: 0;
  bottom: 0;
  height: 3px;
  background: #8c0821 !important;
  opacity: 0;
}

/* .section-header .navbar-collapse .nav-link {
  color: #000;
} */

.section-header .navbar-collapse .nav-link:hover {
  color: #4fab26;
  /* border-bottom: 2px solid #4fab26; */
}

.section-header .navbar-collapse .nav-item .active {
  color: #4fab26;
  /* border-bottom: 2px solid #4fab26; */
}

.section-home {
  margin-top: 17px;
}

/* background image */
.title-outer {
  position: relative;
}

.title-textbox {
  display: flex;
}

.title-text {
  position: relative;
  margin: auto;
  padding: 0 40px 0 40px;
  font-family: Helvetica;
  font-weight: 200;
  font-size: 36px;
}

.home-text {
  font-size: 47px;
}

.home-text1 {
  font-size: 56px;
  color: #8c0821;
}

.home-images {
  position: relative;
  padding-bottom: 8rem;
}

/*section-about*/
.dot {
  width: 6px;
  height: 6px;
  background-color: var(--khoisan-theme-color);
  display: inline-block;
}

.lh-7 {
  line-height: 1.7;
}

.section-about {
  margin-top: 30px;
}

.about-info {
  font-size: 19px;
}

.crimson-text {
  font-family: "Crimson Text", serif;
}

.inter-text {
  font-family: "Inter", sans-serif;
}

.btn-hover-theme {
  background-color: transparent;
  outline: none;
  border: none;
  transition: var(--trans2);
  padding: 10px 16px;
  width: 100%;
  margin-top: 1rem;
}

.video-modal {
  background-color: #000;
}

.btn-hover-theme:hover {
  background-color: var(--khoisan-theme-color);
  color: var(--btn-text);
}

footer .title li {
  transition: var(--trans2);
  border-bottom: 1px solid transparent;
  cursor: pointer;
  width: 80%;
}

footer .title li:hover {
  color: var(--khoisan-theme-color);
  border-color: var(--khoisan-theme-color);
}

.gallery-section {
  margin-left: 95px;
}

.footer-icons a {
  text-decoration: none;
  font-size: 24px;
  color: #000 !important;
}

.second img {
  height: 15rem;
  width: -webkit-fill-available !important;
  object-fit: cover;
  object-position: top;
}

@media (max-width: 768px) {
  .image1 {
    margin-left: 37px;
    margin-top: 100px;
  }

  .image2 {
    width: 32%;
    left: 0px;
  }

  .image3 {
    width: 30%;
    left: 286px;
    top: 235px;
    height: 124px;
  }
}

.hr-line {
  color: #8c0821;
}

.article-hr-line {
  height: 2px;
  border-width: 0;
  color: gray;
  background-color: gray;
}

.bullet-list {
  list-style-type: disc;
}

.bullet-type {
  list-style-type: circle;
}

.top-fixed {
  position: sticky !important;
  top: 0;
  z-index: 1020;
  background-color: white;
  box-shadow: rgba(0, 0, 0, 0.1) 0px 5px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px;
}

/* Zoom In #1 */
.hover01 a img {
  -webkit-transform: scale(1);
  transform: scale(1);
  -webkit-transition: 0.3s ease-in-out;
  transition: 0.3s ease-in-out;
}

.hover01 a:hover img {
  -webkit-transform: scale(1.1);
  transform: scale(1.1);
}

.auth-buttons a {
  background: #8c0821;
  border: #8c0821;
  color: #fff;
}

.auth-buttons {
  background: #8c0821;
  border: #8c0821;
  color: #fff;
}

.auth-buttons a:hover {
  background: #8c0821;
  border: #8c0821;
  color: #fff;
}

.crimson-text {
  font-family: "Crimson Text", serif;
}
