body {
    background: #c2aec5;
    color: white;
  }
  
  .App {
    font-family: sans-serif;
    text-align: center;
  }
  
  .playButton {
    background: OrangeRed;
    color: white;
    border: none;
    width: 60px;
    height: 60px;
    border-radius: 50%!important;
    outline: none;
    text-transform: uppercase;
    transition: all 0.2s ease;
  }
  
  .playButton:hover {
    background: #d63d05;
  }
  
  .playlist {
    text-align: left;
    border-top: 1px solid rgba(0, 0, 0, 0.2);
  }
  
  .playlist-item {
    color: #444;
    border-bottom: 1px solid rgba(0, 0, 0, 0.2);
    cursor: pointer;
    padding: 1em;
  }
  
  .playlist-item.selected {
    font-weight: bold;
  }
  
  .playlist-item:hover {
    background: rgba(0, 0, 0, 0.1);
  }
  
  .controls {
    text-align: left;
    padding: 1em;
  }
  